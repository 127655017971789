<template>
  <v-dialog v-model="internalDialog" persistent max-width="640px">
    <v-card class="border__top">
      <v-card-title>
        {{ $t("abrapa.titles.productRegistration") }}
      </v-card-title>
      <v-card-text class="pa-6">
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="product.name"
            outlined
            dense
            color="primary"
            :rules="requireRules"
          >
            <template v-slot:label>
              {{ $t("abrapa.fields.name") }}
              <strong class="red--text">*</strong>
            </template>
          </v-text-field>
          <v-text-field
            v-model="product.gtin"
            outlined
            dense
            color="primary"
            @input="clearPoints($event, product)"
            @keyup="validGtin14($event, product)"
            @keydown="validGtin14Delete($event, product)"
            :rules="requireAndGTIN14Rules(13)"
          >
            <template v-slot:label>
              {{ $t("abrapa.fields.gtin") }}
              <strong class="red--text">*</strong>
            </template>
          </v-text-field>
          <v-textarea
            v-model="product.description"
            :label="$t('abrapa.fields.description')"
            outlined
            dense
            color="primary"
            counter
            maxlength="250"
            :rules="lengthRules(250).concat(limitEnterKeyPress)"
          ></v-textarea>
          <v-text-field
            v-model="product.category"
            outlined
            dense
            color="primary"
            :rules="requireRules"
          >
            <template v-slot:label>
              {{ $t("abrapa.fields.category") }}
              <strong class="red--text">*</strong>
            </template>
          </v-text-field>
          <v-select
            v-model="selectedStage"
            :items="stages"
            outlined
            dense
            color="primary"
            item-color="primary"
            :rules="selectRule"
          >
            <template v-slot:label>
              {{ $t("abrapa.fields.stage") }}
              <strong class="red--text">*</strong>
            </template>
            <template v-slot:selection="{ item }">
              {{ $t(`abrapa.stages.${item}`) }}
            </template>
            <template v-slot:item="{ item }">
              {{ $t(`abrapa.stages.${item}`) }}
            </template>
          </v-select>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn color="neuter" text small @click="close" :disabled="loading">{{ $t("general.buttons.close") }}</v-btn>
        <v-btn color="primary" class="t-bw-primary--text" elevation="0" small @click="save" :disabled="!valid" :loading="loading">{{ $t("general.buttons.confirm") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { IntegrationConfigMixin } from "@integrations/abrapa/mixins/integration-config.mixin";
import { IntegrationDialogMixin } from "@/module/integrations/abrapa/mixins/integration-dialog.mixin";
import { GtinMixin } from "@/mixins/gtin.mixin";
import {mapActions, mapGetters} from "vuex";
import LanguageService from "@/services/LanguajeService";

export default {
  name: "IntegrationProductDialog",
  mixins: [IntegrationConfigMixin, IntegrationDialogMixin, GtinMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      internalDialog: this.value,
      product: {
        name: '',
        gtin: '',
        description: '',
        category: ''
      },
      selectedStage: null
    };
  },
  watch: {
    value(val) {
      this.internalDialog = val;
    },
    internalDialog(val) {
      this.$emit('input', val);
    }
  },
  methods: {
    ...mapActions({
      createProduct: 'abrapa/CREATE_PRODUCT'
    }),
    async save() {
      if (this.valid && !this.loading) {
        this.loading = true;
        try {
          const payload = {
            product: {
              ...this.product,
              name: LanguageService.setKey3(
                this.product.name,
                this.product.name,
                this.languageStructure
              ),
              description: LanguageService.setKey3(
                this.product.description,
                this.product.description,
                this.languageStructure
              ),
              category: LanguageService.setKey3(
                this.product.category,
                this.product.category,
                this.languageStructure
              )
            },
            stage: this.selectedStage
          };
          await this.createProduct(payload);
        } catch (error) {
          console.error("Failed to save product:", error);
        } finally {
          this.loading = false;
          this.close();
        }
      }
    },
    close() {
      this.$emit('input', false);
      this.$refs.form.reset();
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">
  .border__top {
    border-top: 6px solid var(--v-primary-base);
  }
</style>
