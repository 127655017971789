<template>
  <ReplaceColumnWrapper :header="header" :items="items" :length="length" :text="text" :selected="selected" :selected-field="selectedStore">
    <template v-slot:fields>
      <v-col cols="12">
        <v-autocomplete v-model="selectedStore" :items="stores" class="mt-4" item-value="name" item-text="name"
                  outlined dense color="primary" item-color="primary" :rules="required">
          <template v-slot:label>
            {{ $t("abrapa.fields.store") }}
            <strong class="red--text">*</strong>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-content>
                {{ item.name + " (" + item.url + ")" }}
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            {{ item.name + " (" + item.url + ")" }}
          </template>
        </v-autocomplete>
      </v-col>
    </template>
  </ReplaceColumnWrapper>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import ReplaceColumnWrapper from "@/module/integrations/abrapa/components/ReplaceColumnWrapper.vue";

  export default {
    components: { ReplaceColumnWrapper },
    props: ['header', 'items', 'length', 'text', 'selected'],
    data() {
      return {
        selectedStore: null,
        stores: [],
        textProps: {
          persistentHint: true,
          small: true,
          flat: true,
          outlined: true,
          dense: true,
          clearable: true,
          color: "primary",
          itemColor: "primary",
        },
      };
    },
    async created() {
      this.stores = await this.fetchStores();
    },
    computed: {
      ...mapGetters({
        required: "general/requireRules",
      }),
    },
    methods: {
      ...mapActions({
        fetchStores: "abrapa/FETCH_STORES",
      }),
    }
  };
</script>
