<template>
  <ReplaceColumnWrapper :header="header" :items="items" :length="length" :text="text" :selected="selected" :selected-field="selectedLocation">
    <template v-slot:fields>
      <v-col cols="12">
        <v-autocomplete
          class="mt-4"
          :items="displayedStorages"
          :loading="loading"
          :no-data-text="$t('general.fields.noDataAvailable')"
          v-bind="textProps"
          v-model="selectedLocation"
          item-text="control_label"
          item-value="gln"
          :return-object="false"
          clearable
          @update:search-input="handleStoragesSearchChange"
          :rules="required"
        >
          <template v-slot:label>
            {{ $t('records.fields.originReg') }}
            <strong class="red--text">*</strong>
          </template>
          <template v-slot:item="{ item, attrs }">
            <read-more
              :classes="'mb-0'"
              :text="`${item.control_label} (${item.gln})`"
              :max-chars="40"
              :font-size="14"
            />
          </template>
          <template v-slot:append-item>
            <AFooterPaginatorField
              :items-length="filteredStorages.length"
              :items-per-page="pagination.storages.itemsPerPage"
              :itemPerPageText="false"
              :current-page="pagination.storages.currentPage"
              :valid="true"
              @update:current-page="(e) => updatePagination('storages', { page: e.page, itemsPerPage: e.itemsPerPage })"
              @update:items-per-page="(e) => updatePagination('storages', { page: e.page, itemsPerPage: e.itemsPerPage })"
            />
          </template>
        </v-autocomplete>
      </v-col>
    </template>
  </ReplaceColumnWrapper>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import AFooterPaginatorField from "@/components/common/AFooterPaginatorField.vue";
  import ReadMore from "@/components/ReadMoreComponent.vue";
  import ReplaceColumnWrapper from "@/module/integrations/abrapa/components/ReplaceColumnWrapper.vue";

  export default {
    components: { ReplaceColumnWrapper, ReadMore, AFooterPaginatorField },
    props: ['header', 'items', 'length', 'text', 'selected'],
    data() {
      return {
        selectedLocation: null,
        storagesSearch: '',
        storageData: [],
        loading: false,
        textProps: {
          persistentHint: true,
          small: true,
          flat: true,
          outlined: true,
          dense: true,
          clearable: true,
          color: "primary",
          itemColor: "primary",
        },
        pagination: {
          storages: {
            currentPage: 1,
            itemsPerPage: 10,
          }
        },
        loadings: {
          origin: false,
          destination: false,
        },
      };
    },
    async created() {
      await this.fetchStorages(null);
      this.$set(this, 'storageData', this.sStorageDevices || []);
    },
    computed: {
      ...mapGetters({
        required: "general/requireRules",
        sStorageDevices: "storage/sStorageDevices",
      }),
      displayedStorages() {
        if (!this.filteredStorages) {
          return [];
        }
        const start = (this.pagination.storages.currentPage - 1) * this.pagination.storages.itemsPerPage;
        const end = start + this.pagination.storages.itemsPerPage;
        return this.filteredStorages.slice(start, end);
      },
      filteredStorages() {
        if (!this.storageData) {
          return [];
        }
        if (!this.storagesSearch) {
          return this.storageData;
        }
        return this.storageData.filter(storage =>
          storage.control_label.toLowerCase().includes(this.storagesSearch.toLowerCase())
        );
      },
    },
    methods: {
      ...mapActions({
        fetchSStorageDevices: "storage/fetchSStorageDevices"
      }),
      async fetchStorages(e) {
        await this.fetchSStorageDevices([
          {
            stringFilter: e || "",
            isActive: true,
          },
          this.$toast,
        ]).finally(() => {
          this.pagination.storages.currentPage = 1;
          this.$set(this, 'storageData', this.sStorageDevices || []);
          this.$set(this.loadings, "destination", false);
        });
      },
      updatePagination(property, { page, itemsPerPage }) {
        this.pagination[property].currentPage = page;
        if (itemsPerPage) {
          this.pagination[property].itemsPerPage = itemsPerPage;
        }
      },
      handleStoragesSearchChange(searchTerm) {
        this.storagesSearch = searchTerm;
        this.updatePagination('storages', { page: 1, itemsPerPage: this.pagination.storages.itemsPerPage });
      },
    }
  };
</script>
